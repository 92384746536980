import dynamic from "next/dynamic";
import { Container, Typography, Box, useMediaQuery, alpha, Grid } from "@mui/material";
import { useTheme } from "@mui/system";

import MainLayout from "../ui/layouts/main";
import { HotelListSlider } from "../ui/components/property/HotelListSlider";

import { FormSearch } from "../ui/components/FormSearch/FormSearch";
import { SidebarFilterWidgetLazy } from "../ui/widgets/SidebarFilterWidget/SidebarFilterWidgetLazy";

import { useTranslation } from "../fe-ui/hooks/use-translation";
import { OffersListWidgetLazy } from "../ui/widgets/OffersListWidget/OffersListWidgetLazy";

import {
  BannerPromoListResponseDataItem,
  BannerSearchListResponseDataItem,
  HeaderBannerListResponseDataItem,
} from "../api/content-client/service";
import { composeServerSideProps } from "next-composition";
import { withTranslations } from "../server/ssrprops/withTranslations";
import { withAllHotelsSsrProps } from "../server/ssrprops/withAllHotelsSsrProps";
import { withBannersSsrProps } from "../server/ssrprops/withBannersSsrProps";
import { withBannerPromosSsrProps } from "../server/ssrprops/withBannerPromosSsrProps";
import { withBannersSearchSsrProps } from "../server/ssrprops/withBannerSearchSsrProps";
import dataTestIds from "../utils/data-test-ids";
import { AttributeSlider } from "../ui/components/AttributeSlider/AttributeSlider";
import { withSeoSsrProps } from "../server/ssrprops/withSeoSsrProps";
import { useEffect, useMemo, useState } from "react";
import { HEADER } from "../config-global";
import SwipeableDrawerForSearch from "../ui/components/FormSearch/SwipeableDrawerForSearch";
import SwipeableDrawerPuller from "../ui/components/FormSearch/SwipeableDrawerPuller";
import { useRecoilState } from "recoil";
import { uiBottomDrawerOpenedAtom } from "../state/ui-state";
import FormSearchSummarizedInput from "../ui/components/FormSearch/FormSearchSummarizedInput";
import { useInitialStateFromQuery } from "../hooks/useInitialStateFromQuery";
import type { ExtendedHotelDto, HotelUnitGroupAttributeGroupsDto } from "../api/aggregation-client/service";
import { withUnitGroupAttributesSsrProps } from "../server/ssrprops/withUnitGroupAttributesSsrProps";

import { BannerWidget } from "../ui/widgets/BannerWidget/BannerWidget";
// import { MapWidgetLazy } from "../ui/widgets/MapWidget/MapWidgetLazy";

const BannerPromos = dynamic(() => import("../ui/components/main/BannerPromos").then((x) => x.BannerPromos), {
  ssr: false,
});

HomePage.getLayout = (page: React.ReactElement, props: HomePageProps) => {
  return (
    <MainLayout
      withBackgroundImage
      backgroundImageContent={() => <BackgroundImageContent hotels={props.hotels} />}
      banners={props.banners}
    >
      {page}
    </MainLayout>
  );
};

type HomePageProps = {
  hotels: ExtendedHotelDto[];
  banners: HeaderBannerListResponseDataItem[];
  bannerPromos: BannerPromoListResponseDataItem[];
  bannersSearch: BannerSearchListResponseDataItem[];
  unitAttributes: HotelUnitGroupAttributeGroupsDto[];
};

function BackgroundImageContent({ hotels }: { hotels: ExtendedHotelDto[] }) {
  const t = useTranslation();
  const theme = useTheme();

  const isXS = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Container id={"header-search-form"} maxWidth={"lg"} sx={{ mt: 25 }}>
      <Box>
        <Typography
          sx={{
            color: "#FFFFFF",
            textAlign: "center",
            fontWeight: 600,
            fontSize: isXS ? "24px" : "34px",
            lineHeight: isXS ? "28px" : "36px",
            textShadow: `0px 4px 4px ${alpha("#000000", 0.53)}`,
            mb: isXS ? 4 : 6,
          }}
        >
          {t("Home_header_search_title")}
        </Typography>
        <FormSearch navigateToSearchPage hotels={hotels} hasShadow />
      </Box>
    </Container>
  );
}

function HomePage({ hotels, bannerPromos, bannersSearch, unitAttributes }: HomePageProps) {
  useInitialStateFromQuery();

  const t = useTranslation();
  const theme = useTheme();

  const isXS = useMediaQuery(theme.breakpoints.down("sm"));

  // const br = isXS ? 2 : 8;
  const hasBannerPromos = bannerPromos && bannerPromos.length > 0;
  const hasUnitAttributes = unitAttributes && unitAttributes.length > 0;

  const [showDrawer, setShowDrawer] = useState<boolean>(false);
  const [isDrawerOpen, setDrawerOpen] = useRecoilState(uiBottomDrawerOpenedAtom);

  useEffect(() => {
    if (typeof window === "undefined") {
      return;
    }

    window.addEventListener("scroll", handleSearchFormScroll);
    return () => window.removeEventListener("scroll", handleSearchFormScroll);
  }, []);

  const handleSearchFormScroll = () => {
    const headerSearch = document.querySelector("#header-search-form");
    if (!headerSearch) {
      return;
    }
    const headerSearchRect = headerSearch.getBoundingClientRect();
    const isHeaderSearchBottomHidden = headerSearchRect.bottom <= (isXS ? HEADER.H_MOBILE : HEADER.H_MAIN_DESKTOP);
    setShowDrawer(isHeaderSearchBottomHidden);
  };

  const attributesPhotoSlider = useMemo(() => {
    const photoAttrs = unitAttributes
      .map((g) => g.attributes)
      .flat()
      .filter((attr) => !!attr.photo);

    if (photoAttrs.length === 0) {
      return null;
    }

    return (
      <Box mb={2}>
        <Typography variant={"h4"} textAlign={"left"} sx={{ mb: 2 }}>
          {t("index-search-by-type-title")}
        </Typography>
        <Grid container spacing={2}>
          {photoAttrs.map((a) => (
            <Grid item key={a.id} xs={12} sm={6} md={3}>
              <BannerWidget
                containerSx={{
                  minHeight: 100,
                }}
                title={a.title}
                titleVariant="h5"
                linkUrl={`/search?filters=${a.id}`}
                media={{
                  data: {
                    attributes: {
                      url: a.photo,
                    },
                  },
                }}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    );
  }, [unitAttributes, t]);

  const forcedSpecialOffersAttributes = useMemo(() => {
    if (process.env.NODE_ENV === "production") {
      // @see https://app.forestadmin.com/Seaya/Production/Operations/data/HotelUnitAttribute/index/record/HotelUnitAttribute/113/details?sort=-id
      // Special offers attr on production
      return [918];
    }
    // Ignore on test
    return [];
  }, []);

  return (
    <>
      {isXS && (
        <>
          <SwipeableDrawerPuller visible={showDrawer}>
            <FormSearchSummarizedInput onClick={() => setDrawerOpen(true)} />
          </SwipeableDrawerPuller>

          <SwipeableDrawerForSearch
            open={isDrawerOpen}
            onOpen={() => setDrawerOpen(true)}
            onClose={() => setDrawerOpen(false)}
          >
            <FormSearch isSheet navigateToSearchPage hotels={hotels} onSubmit={() => setDrawerOpen(false)} />
          </SwipeableDrawerForSearch>
        </>
      )}

      {/* <Container maxWidth={"lg"}>
        <Card>
          <CardContent sx={{ height: 500 }}>
            <MapWidgetLazy
              data={[
                { name: "xz", capital: "ba111u", country_code: "123", latlng: [40.567142, 49.981427] },
                { name: "xz", capital: "ba111u", country_code: "123", latlng: [40.647464, 50.018687] },
                { name: "xz", capital: "ba111u", country_code: "123", latlng: [40.582861, 49.749689] },

                { latlng: [40.592682235357216, 49.966046295514495], name: "Caspian Dream Liner	" },
                { latlng: [40.58956977802138, 49.96326953207969], name: "Skyline	" },
                { latlng: [40.584996563129145, 49.945981537841405], name: "Marina Village	" },
                { latlng: [40.58758251903045, 49.95485559551418], name: "Palazzo Del Mare	" },
                { latlng: [40.58988194268672, 49.98665483107497], name: "Lighthouse 2	" },
                { latlng: [40.58695812373406, 49.953122993660244], name: "Palm Beach	" },
                { latlng: [40.587314966707396, 49.96857895504049], name: "Polo Residences	" },
                { latlng: [40.57809807636678, 49.93506781085867], name: "Park Residences 2	" },
                { latlng: [40.58600675355445, 49.96769703156427], name: "Woodville	" },
                { latlng: [40.58982553210852, 49.97463346455638], name: "White Residences	" },
                { latlng: [40.591508328979174, 49.987362880169364], name: "The Grand	" },
                { latlng: [40.59088247141924, 49.983032668531955], name: "White Villas	" },
                { latlng: [40.58864989666729, 49.980335753186715], name: "Palazzo Del Sol	" },
                { latlng: [40.58762390781037, 49.97526622620435], name: "Palazzo Del Verdi & Del Rosa	" },
                { latlng: [40.592299802505266, 49.98661960832648], name: "Lighthouse Residence	" },
                { latlng: [40.588612619532995, 49.98522516378313], name: "Park Residences	" },
                { latlng: [40.588048091014436, 49.98144842364931], name: "Palazzo Della Luna	" }
              ]}
            />
          </CardContent>
        </Card>
      </Container> */}

      <Container maxWidth={"lg"}>
        {hasBannerPromos ? <BannerPromos banners={bannerPromos} /> : null}

        {attributesPhotoSlider}

        {hasUnitAttributes ? (
          <Box mb={4}>
            <AttributeSlider
              unitAttributes={unitAttributes}
              dataTestId={dataTestIds.PAGES.HOME.UNIT_GROUP_ATTRIBUTES_SLIDER}
            />
          </Box>
        ) : null}

        <Box mb={2}>
          <OffersListWidgetLazy
            bannersSearch={bannersSearch}
            bannerBlockId="mainPage1"
            showTotalPrice={false}
            guestsCount={2}
            type="future"
            perPage={4}
            infinite={false}
            dataTestId={dataTestIds.PAGES.HOME.FUTURE_OFFERS_CONTAINER}
          />
        </Box>
      </Container>

      <Box
        sx={{
          my: 4,
          backgroundColor: theme.palette.grey[200],
          py: 4,
          sm: {
            py: 3,
          },
        }}
      >
        <Typography variant={"h4"} textAlign={"center"} sx={{ mb: 4 }}>
          {t("Index_ourHotels")}
        </Typography>
        <Box sx={{ maxWidth: 1600, m: "auto" }}>
          <HotelListSlider items={hotels} />
        </Box>
      </Box>

      <Container maxWidth={"lg"}>
        <Typography variant={"h4"} textAlign={"center"} sx={{ my: 2 }}>
          {t("Index_specialOffers")}
        </Typography>
        <Box mb={10}>
          <OffersListWidgetLazy
            bannersSearch={bannersSearch}
            bannerBlockId="mainPage2"
            showTotalPrice={false}
            guestsCount={2}
            type="future"
            perPage={12}
            infinite={false}
            dataTestId={dataTestIds.PAGES.HOME.SPECIAL_OFFERS_CONTAINER}
            withAttributesContext={false}
            withGuestsContext={false}
            forceAttributes={forcedSpecialOffersAttributes}
          />
        </Box>
      </Container>

      <SidebarFilterWidgetLazy />
    </>
  );
}

export default HomePage;

export const getServerSideProps = composeServerSideProps({
  use: [
    withAllHotelsSsrProps,
    withTranslations,
    withBannersSsrProps,
    withBannerPromosSsrProps,
    withBannersSearchSsrProps,
    withSeoSsrProps,
    withUnitGroupAttributesSsrProps,
  ],
});
