import { ReactNode } from "react";
import { Box, Container, Stack, SwipeableDrawer, useTheme } from "@mui/material";

type Props = {
  open: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  children: ReactNode;
};

const iOS = typeof navigator !== "undefined" && /iPad|iPhone|iPod/.test(navigator.userAgent);

export default function SwipeableDrawerForSearch({ open, onOpen, onClose, children }: Props) {
  const theme = useTheme();

  const handleOpen = () => {
    // setOpen(true);
    onOpen?.();
  };

  const handleClose = () => {
    // setOpen(false);
    onClose?.();
  };

  return (
    <SwipeableDrawer
      anchor={"bottom"}
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
      swipeAreaWidth={0}
      ModalProps={{
        keepMounted: true,
      }}
      PaperProps={{
        sx: {
          borderRadius: "8px 8px 0px 0px",
        },
      }}
      disableBackdropTransition={!iOS}
      disableDiscovery={iOS}
    >
      <Container sx={{ paddingTop: "16px", paddingBottom: "32px" }}>
        <Stack sx={{ display: "flex", flexDirection: "row", justifyContent: "center", paddingBottom: "16px" }}>
          <Box
            sx={{
              width: "30px",
              height: "6px",
              backgroundColor: theme.palette.divider,
              borderRadius: "100px",
            }}
          ></Box>
        </Stack>

        {children}
      </Container>
    </SwipeableDrawer>
  );
}
