// @mui
import { Box } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import { useTheme } from "@mui/material/styles";

import { useSliderSettings } from "./useSliderSettings";
import { SamplePrevArrow } from "./compounds/SamplePrevArrow";
import { SampleNextArrow } from "./compounds/SampleNextArrow";
import { AttributeSliderItem } from "./compounds/AttributeSliderItem/AttributeSliderItem";
import type { HotelUnitGroupAttributeGroupsDto } from "../../../api/aggregation-client/service";

type Props = {
  dataTestId: string;
  unitAttributes: HotelUnitGroupAttributeGroupsDto[];
};

export const AttributeSlider = ({ dataTestId, unitAttributes: allUnitGroupAttributes }: Props) => {
  const theme = useTheme();
  const settings = useSliderSettings();

  if (!allUnitGroupAttributes || !allUnitGroupAttributes.length) {
    return null;
  }

  const attributes = allUnitGroupAttributes
    .map((g) => g.attributes)
    .flat()
    .filter((attr) => !!attr.icon)
    .filter((attr) => attr.show_in_main_slider);

  if (!attributes.length) {
    return null;
  }

  return (
    <Box mx="auto" px={4} position={"relative"} data-testid={dataTestId}>
      <Swiper {...settings}>
        {attributes.map((attr) => (
          <SwiperSlide key={attr.code}>
            <AttributeSliderItem attribute={attr} />
          </SwiperSlide>
        ))}
      </Swiper>
      <SamplePrevArrow className="arrow-left" bgColor={theme.palette.background.default} />
      <SampleNextArrow className="arrow-right" bgColor={theme.palette.background.default} />
    </Box>
  );
};
