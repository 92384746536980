import { Box, Chip, Typography, TypographyTypeMap } from "@mui/material";
import { Link } from "../../../fe-ui/atoms/Link/Link";
import { ContentRendererBody } from "../../components/ContentRenderer/ContentRendererBody";
import type { SxProps } from "@mui/system";
import type { Theme } from "@mui/material/styles/createTheme";

type Props = {
  body?: any;
  containerSx?: SxProps<Theme>;
  label?: string;
  linkUrl: string;
  media?: {
    data?: {
      attributes?: {
        url?: string;
      };
    };
  };
  title?: string;
  titleVariant?: TypographyTypeMap["props"]["variant"];
};

export const BannerWidget = ({ body, containerSx, label, linkUrl, title, media, titleVariant = "h4" }: Props) => (
  <Box
    component={Link}
    href={linkUrl}
    sx={{
      position: "relative",
      backgroundImage: `url(${media?.data?.attributes?.url})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      borderRadius: 2,
      display: "grid",
      gap: 1,
      alignContent: "flex-end",
      padding: 3,
      minHeight: 200,
      height: "100%",
      overflow: "hidden",
      zIndex: 0,
      "&:hover": {
        textDecoration: "none",
      },
      "&:before": {
        background: "linear-gradient(180deg, rgba(22, 28, 36, 0) 0%, rgba(12, 21, 39, 1) 100%)",
        content: '""',
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 0,
        pointerEvents: "none",
      },
      ...containerSx,
    }}
  >
    {title ? (
      <Typography
        color="primary.contrastText"
        variant={titleVariant}
        sx={{
          position: "relative",
        }}
      >
        {title}
      </Typography>
    ) : null}
    {body ? (
      <ContentRendererBody
        body={body}
        paragraphVariant="bodySm"
        paragraphSx={{
          color: "primary.contrastText",
          position: "relative",
        }}
      />
    ) : null}
    {label ? (
      <Chip
        color="error"
        variant="filled"
        label={label}
        size="medium"
        sx={{
          fontSize: (theme) => theme.typography.h5.fontSize,
          fontWeight: (theme) => theme.typography.h5.fontWeight,
          lineHeight: (theme) => theme.typography.h5.lineHeight,
          pointerEvents: "none",
          position: "absolute",
          left: 24,
          top: 24,
        }}
      />
    ) : null}
  </Box>
);
