import { Typography, TypographyPropsVariantOverrides } from "@mui/material";
import { BlocksRenderer } from "@strapi/blocks-react-renderer";

import type { SxProps } from "@mui/system";
import type { Theme } from "@mui/material/styles/createTheme";
import type { Variant } from "@mui/material/styles/createTypography";
import type { OverridableStringUnion } from "@mui/types";

interface Props {
  body?: any;
  paragraphSx?: SxProps<Theme>;
  paragraphVariant?: OverridableStringUnion<Variant | "inherit", TypographyPropsVariantOverrides> | undefined;
}

export function ContentRendererBody({ body, paragraphVariant, paragraphSx }: Props) {
  if (!body) return null;

  return (
    <BlocksRenderer
      blocks={{
        paragraph: (p) => (
          <Typography
            component="p"
            variant={paragraphVariant || "body1"}
            sx={{
              ...paragraphSx,
              whiteSpace: "pre-wrap",
              wordBreak: "normal",
            }}
            {...p}
          />
        ),
      }}
      content={body}
    />
  );
}
