import { useRef } from "react";
import { Box, CircularProgress } from "@mui/material";
import { HotelCard } from "../HotelCard/HotelCard";
import { SliderDefault } from "../../SliderDefault/SliderDefault";
import { ExtendedHotelDto } from "../../../../api/aggregation-client/service";

type Props = {
  items?: ExtendedHotelDto[];
  isLoading?: boolean;
};

export const HotelListSlider = (props: Props) => {
  const { items, isLoading } = props;
  const containerRef = useRef<HTMLInputElement>(null);

  if (isLoading) {
    return (
      <Box sx={{ margin: "1rem auto 0" }}>
        <CircularProgress color={"primary"} />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        position: "relative",
        padding: "0 50px",
        minHeight: 300,
        sm: {
          padding: 0,
        },
      }}
      ref={containerRef}
    >
      {!!items && (
        <SliderDefault
          breakpoints={{
            600: {
              slidesPerView: 2,
            },
            900: {
              slidesPerView: 3,
            },
            1200: {
              slidesPerView: 4,
            },
            1400: {
              slidesPerView: 5,
            },
          }}
          items={items}
          ItemComponent={HotelCard}
        />
      )}
    </Box>
  );
};
