import { Box, Container, useTheme } from "@mui/material";
import { ReactNode } from "react";

type Props = {
  visible: boolean;
  children: ReactNode;
};

export default function SwipeableDrawerPuller({ visible, children }: Props) {
  const theme = useTheme();

  return (
    <Box
      sx={{
        backgroundColor: "white",
        borderTop: `1px solid ${theme.palette.divider}`,
        boxShadow: "0px 12px 24px -4px #919EAB1F, 0px 0px 2px 0px #919EAB33",
        position: "fixed",
        width: "100%",
        paddingTop: "16px",
        paddingBottom: "24px",
        bottom: 0,
        zIndex: 1000,
        transform: visible ? undefined : "translateY(100%)",
        transition: "all 250ms ease",
      }}
    >
      <Container>{children}</Container>
    </Box>
  );
}
